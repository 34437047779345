import React, { useEffect, useState } from 'react';
import './widjetHandler.scss';

const WidgetHandler = ({ params, address, handleAddToAddress }) => {
  const [chosenPost, setChosenPost] = useState('');
  const [addressPost, setAddressPost] = useState('');
  const [pricePost, setPricePost] = useState('');
  const [timePost, setTimePost] = useState('');
  const [isChecked, updateIsChecked] = useState(false);

  useEffect(() => {
    handleAddToAddress({ ...address, chosenPost, addressPost, pricePost, timePost });
  }, [chosenPost, addressPost, pricePost, timePost]);

  useEffect(() => {
    if (isChecked) {
      setChosenPost('г. Санкт-Петербург,');
      setAddressPost(' ул.Лисичанская д.5');
      setPricePost('0');
      setTimePost('1-2');
    } else {
      setChosenPost('');
      setAddressPost('');
      setPricePost('');
      setTimePost('');
    }
    handleAddToAddress({ ...address, chosenPost, addressPost, pricePost, timePost });
  }, [isChecked]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      new window.CDEKWidget({
        from: {
          country_code: 'RU',
          city: 'Санкт-Петербург',
        },
        root: 'forpvz',
        apiKey: 'dc1ad195-bfc7-45eb-b833-c719b7440854', // Ваш ключ Яндекс.Карт
        servicePath: 'https://арсеналъ-1.рф/service.php?action=offices', // Путь к вашему service.php
        goods: ${JSON.stringify(params.map(item => ({
          length: item.length_in_packaging_mm / 1000,
          width: item.width_in_packaging_mm / 1000,
          height: item.height_in_packaging_mm / 1000,
          weight: (item.weight_in_packaging_g / 1000) * item.quantity,
        })))},
        defaultLocation: 'Москва', // Обязательно укажите defaultLocation
        onChoose: function (delivery, tariff, address) {
          console.log('onChoose called with:', delivery, tariff, address);
          updateIsChecked(false);
          setChosenPost(address.code);
          setAddressPost(address.address);
          const price = tariff.delivery_sum < 500 ? 500 : Math.ceil(tariff.delivery_sum / 100) * 100;
          setPricePost(price);
          setTimePost(tariff.period_min + '-' + tariff.period_max);
        },
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [params]);

  return (
    <div className='widget-handler'>
      <h4>Выберите пункт выдачи</h4>
      <div id="forpvz" style={{ height: "500px" }}></div>
      <div className="pickup">
        <CustomRoundedCheckbox
          isChecked={isChecked}
          onClick={() => updateIsChecked(!isChecked)}
        />
        <h2 className='pickup-header'>Самовывоз</h2>
      </div>
      {addressPost && (
        <>
          <h4>Ваш пункт выдачи</h4>
          <span className='address_widjet'>{chosenPost}</span>
          <span className='address_widjet'>{addressPost}</span>
          <h4>Время и стоимость доставки</h4>
          <span className='address_widjet'>Стоимость: {pricePost} руб.</span>
          <span className='address_widjet'>Время доставки: {timePost} дней</span>
        </>
      )}
    </div>
  );
};

export default WidgetHandler;

const CustomRoundedCheckbox = ({ onClick, isChecked }) => {
  return (
    <div className="custom-rounded-checkbox approved-checkbox">
      <input type="checkbox" checked={isChecked} onChange={onClick} />
      <span onClick={onClick} tabIndex={1} />
    </div>
  );
};
