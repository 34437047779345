import useCounter from '../../hooks/useCounter';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'; 
import SearchHeader from '../searchHeader/search-header';
import phone from '../../resources/img/ico/phone.svg';
import cart from '../../resources/img/ico/cart.svg';
import like from '../../resources/img/ico/like.svg';
import avatar from '../../resources/img/ico/avatar.svg';
import hamburger from '../../resources/img/ico/hamburger-menu.svg';
import whatsapp from '../../resources/img/ico/whatsapp.svg';

import './app-header.scss';

const AppHeader = ({setState, state}) => {
    const { cartItems } = useCounter();
    const [cartItemCount, setCartItemCount] = useState(cartItems.length);

    useEffect(() => {
      setCartItemCount(cartItems.length);
    }, [cartItems.length]);

    
    return(
        <div className='app-header'>
            <div className='container'>
                <header>

                    <Link to='/catalog' className='catalog__btn'>
                        <p className='text__catalog' >КАТАЛОГ</p>
                    </Link>
                   <img src={hamburger} alt='hamburger' className='hamburger' onClick={(e) => {
                        setState(!state)
                         
                   }}/>
                    <SearchHeader/>
                    <div className='phone'>
                        <div className='col-left__phone'> 
                        <img src={phone} className='phone_ico' alt='number'/>
                        </div>
                        <div className='col-right__phone'> 
                            <a href='tel:+79219337214'>+7-921-933-72-14</a>
                            <p>Пн-Пт с 9:00 до 18:00</p>
                        </div>
                        <a href='https://wa.me/79219337214?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82...'>
                            <img src={whatsapp} className='whatsapp' alt='whatsapp'/>
                        </a>
                    </div>
                    <Link to='/favourites'>
                        <img src={like} className='like' alt='like'/>
                    </Link>
                    <Link to='/cart' className='cart'>
                        <img src={cart} alt='cart'/>
                        <div className='count__cart'><p>{cartItemCount}</p></div>
                    </Link>
                    <a href='/authorization'>
                        <img src={avatar} className='avatar' alt='avatar'/>
                    </a>
                </header>
            </div>
        </div>
    )
}

export default AppHeader;